import { Link } from 'react-router-dom';

const Job = () => (
    <div className="content">
       
       
        <Link to="/">🔙 Back to Main Page</Link>


<h2>☕ Coffee Shop Clerk ☕</h2>

<h6>Description</h6>
<p>We're a family-owned business that is looking for an enthusiastic and friendly Coffee Server to join our team! We are looking for someone who is great with people and loves having conversations as our business is a bit different than other coffee shops and cafés. Many folks come by to chit chat with staff while waiting on their orders, so we definitely need somebody who is comfortable with this.</p>

<p>Scheduling is extremely flexible, it’s a pretty laid back work environment but we do get a lot of traffic; especially during the weekends. If you have been a bartender before or something similar, this would be a good opportunity.</p>

<p>We are open seven days a week. On weekdays we open at 5am and close at 1pm. On weekends we open at 7am and close at 12pm on weekends. Shifts are as follows, and you would be able to work the days/times that work best for you!</p>

<ul>
    <li>Weekday Opening: 4:30am-11am</li>
    <li>Weekday Closing: 7am-1:30pm</li>
    <li>Weekend Opening: 6:30am-12pm</li>
    <li>Weekend Closing: 7am-12:30pm</li>
</ul>

<p>Pay is $15/hr but we make very decent cash tips every shift and you aren't required to work a certain number of shifts/days/hours etc. - whatever works for you.</p>

<h6>Responsibilities</h6>

<ul>
    <li><strong>🍩 Donut Pickup:</strong> Opening shifts are responsible for picking up donuts in the morning before opening (about 10 minutes away from the shop)</li>
    <li><strong>😊 Customer Service:</strong> Greet customers and take food and drink orders using our POS system</li>
    <li><strong>🥯 Beverage and Food Preparation:</strong> Make a variety of coffee drinks and prepare sandwich orders (no stovetop cooking required)</li>
    <li><strong>🫘 Inventory Management:</strong> Receive vendor deliveries, put inventory away, sign for deliveries, and write checks as needed</li>
    <li><strong>💛 Teamwork:</strong> Assist colleagues during busy shifts and maintain a clean, organized workspace</li>
</ul>

<h6>Qualifications</h6>

<ul>
    <li>18+ with reliable transportation</li>
    <li>Excellent communication and interpersonal skills</li>
    <li>Ability to work in a fast-paced environment</li>
    <li>Basic math skills and familiarity with POS systems (training provided if needed)</li>
    <li>A positive attitude and great customer service skills</li>
    <li>Comfortable working alone in a food service environment</li>
    <li>Previous experience in food service or retail is a plus but not required</li>
</ul>

<h6>How to Apply</h6>
<p>If this sounds like it could be a good fit, please send an email application directly to <strong>emily@thecoffeecorner.co</strong> and <strong>liam@thecoffeecorner.co</strong>. Please include your resume, or if you don't have one, a description 
of why you want to work with us and describe your work ethic and qualifications.</p>

</div>
);

export default Job;