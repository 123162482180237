const Header = () => (
    <>
    <header className="header">
        <h1 className="title">The Coffee Corner
        <span className="kicker">Good Coffee and Good Company. Muffins Baked Fresh Daily.</span>
        </h1>
        <p className="url">www.thecoffeecorner.co</p>
        <h2 className="desc">
            <p>55 Water Street, Hingham, MA 02043</p>
            <p>M-F 5am-1pm, Sat & Sun 7am-12pm</p>
        </h2>
        <p className="phone">(781)-749-0887</p>
    </header>
    <div className="announcement">
        Happy New Year! We will be closed Jan 1, 2025 for the holiday.
    </div>
    </>
);

export default Header;