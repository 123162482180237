import coffee from '../assets/coffee.png';
import Menu from './Menu';
import { useNavigate } from 'react-router-dom';

const Main = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="content">
                <div className="job-announcement">
                    <div className="label">Looking for part-time work? We are currently hiring Coffee Servers!</div>
                    <div className="button" onClick={() => navigate("/apply")}>Apply Now</div>
                </div>
                <h2>Welcome to the Hingham Coffee Corner ☕</h2>
                <div className="columns">
                    <img src={coffee} alt="" className="image" style={{ 'height': '200px', 'justifySelf': 'center' }}/>
                    <div className="text">
                        <p>Hi there! We have been a family-owned business for the past 15 years. We
                        serve delicious coffee, breakfast sandwiches, donuts, and muffins, all at
                        an affordable price. We truly value every person that comes through our doors. 
                        Stop by for a quick coffee, something to eat, or just for some good company!</p>
                        <p>
                            Looking to place an order? Check out our menu below. When you're ready, <strong>give us a call at (781)-749-0887. </strong>
                            Orders typically take anywhere from 5-25 minutes to prepare, but we'll let you know
                            how long it will take when you call.
                        </p>
                    </div>
                </div>
                <div className="menu">
                    <h2>What's On The Menu</h2>
                    <h3>Please note that we are currently a cash only business. We plan to implement credit card and 
                        debit card sales in mid January. Stay tuned!
                    </h3>
                    <p>We offer a <strong>10% discount</strong> for cash payments. Tax is not included in the prices listed below.
                    We proudly serve Hornstra Farms dairy.</p>
                    <Menu />
                </div>
                <h2>Get in Touch</h2>
                <section className="contact">
                    <div className="map-embed">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d881.1631179007851!2d-70.88423971560222!3d42.24406957577785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e361357e007797%3A0xa07fa4c2c8566d9f!2sCoffee%20Corner!5e0!3m2!1sen!2sus!4v1734019137781!5m2!1sen!2sus" style={{ 'border': 0, 'borderRadius': '15px', 'height': '100%' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className="text">
                        <p>📍 55 Water St, Hingham MA, 02043</p>
                        <p>📞 (781)-749-0887</p>
                        <p><strong>✉️ contact@thecoffeecorner.co</strong></p>
                        <h4 className="hours">Hours</h4>
                        <ul className="shop-hours">
                            <li><strong>Sat-Sun</strong> 7:00am-12:00pm</li>
                            <li><strong>Mon-Fri</strong> 5:00am-1:00pm</li>
                        </ul>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Main;