import './App.css';
import Header from './partials/Header';
import Footer from './partials/Footer';
import Main from './components/Main';
import Job from './components/Job';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  Outlet,
} from 'react-router-dom';

function App() {
  return (
    <Router>
    <div className="App">
      <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/apply" element={<Job />}></Route>
        </Routes>
      <Footer />
    </div>
    </Router>
  );
}

export default App;
